<template>
  <div class="dots-container">
    <div
      v-for="dot in dots"
      :key="dot.id"
      class="dot"
      :style="{
        left: `${dot.x}px`,
        top: `${dot.y}px`,
        width: `${dot.size}px`,
        height: `${dot.size}px`,
        opacity: dot.opacity,
      }"
    ></div>
  </div>
</template>
<script>
export default {
  name: "DotsAnimation",
  data() {
    return {
      dots: [],
      containerWidth: 0,
      containerHeight: 0,
      animationFrameId: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initializeComponent();
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    this.cleanupAnimation();
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    initializeComponent() {
      this.handleResize();
      this.startAnimation();
    },
    handleResize() {
      if (!this.$el || !this.$el.parentElement) return;
      const container = this.$el.parentElement;
      this.containerWidth = container.clientWidth;
      this.containerHeight = container.clientHeight;
    },
    createDot() {
      const size = 10 + Math.random() * 20; // Random size between 10px and 30px
      const x = Math.random() * this.containerWidth;
      const y = Math.random() * this.containerHeight;

      return {
        id: Date.now() + Math.random(),
        x,
        y,
        size,
        opacity: 0,
        lifespan: 2, // Longer lifespan for slower fading
      };
    },
    updateDots() {
      this.dots = this.dots
        .map((dot) => {
          const updatedOpacity =
            dot.opacity + (dot.lifespan > 1 ? 0.005 : -0.005);
          const updatedLifespan = dot.lifespan - 0.005;

          return {
            ...dot,
            opacity: Math.max(0, Math.min(1, updatedOpacity)),
            lifespan: updatedLifespan,
          };
        })
        .filter((dot) => dot.opacity > 0); // Remove fully transparent dots

      // Add new dots at random intervals
      if (Math.random() < 0.02) {
        this.dots.push(this.createDot());
      }
    },
    startAnimation() {
      this.cleanupAnimation();
      const animate = () => {
        this.updateDots();
        this.animationFrameId = requestAnimationFrame(animate);
      };
      animate();
    },
    cleanupAnimation() {
      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId);
        this.animationFrameId = null;
      }
    },
  },
};
</script>
<style scoped>
.dots-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.dot {
  position: absolute;
  border-radius: 50%; /* Make the dots circular */
  background-color: rgba(255, 255, 255, 0.8); /* White dots with transparency */
  transition: opacity 0.1s ease;
}
</style>
