import { createApp } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faBinoculars } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPhone,
  faClock,
  faEnvelope,
  faLocationDot,
  faMapMarkerAlt, // Import the icon
} from "@fortawesome/free-solid-svg-icons";
import App from "@/App.vue";
import "@/assets/tailwind.css";
import AOS from "aos";
import "aos/dist/aos.css";
import router from "@/router";
import VueCarousel from "vue-carousel";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

// Initialize AOS
AOS.init({
  once: true,
});

// Set up FontAwesome
library.add(
  faFacebook,
  faInstagram,
  faClock,
  faWhatsapp,
  faPhone,
  faBars,
  faEnvelope,
  faLocationDot,
  faMapMarkerAlt, // Add the icon here
  faBars,
  faChevronDown,
  faBinoculars
);

const app = createApp(App).use();

// Register plugins
app.use(router);
app.use(VueCarousel);

// Register FontAwesome component globally
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(Toast, {
  position: POSITION.TOP_CENTER,
  timeout: 5000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
  toastClassName:
    "rounded-3xl shadow-lg border-2 border-gray-200 bg-gray-800 text-white font-semibold p-4", // Stylish toast
  bodyClassName: "text-sm", // Additional styling for the body text
});
app.mount("#app");
