<template>
  <div class="container mx-auto px-4 py-8 pt-32">
    <h2 class="text-3xl font-bold text-center mb-8 text-gray-800">
      What Our Noble Clients Have To Say
    </h2>

    <div class="grid md:grid-cols-3 gap-6">
      <div
        v-for="review in reviews"
        :key="review.id"
        class="bg-white shadow-lg rounded-lg p-6 transform transition-all hover:scale-105 hover:shadow-xl"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div class="flex justify-between items-center mb-4">
          <div class="flex">
            <svg
              v-for="n in 5"
              :key="n"
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 inline-block"
              :fill="n <= review.rating ? '#FFD700' : 'none'"
              :stroke="n <= review.rating ? '#FFD700' : '#E0E0E0'"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
              />
            </svg>
          </div>
          <span class="text-sm text-gray-500">{{ review.date }}</span>
        </div>

        <p class="text-gray-600 mb-4 italic">"{{ review.text }}"</p>

        <div class="border-t pt-4">
          <h3 class="font-semibold text-gray-800">{{ review.name }}</h3>
          <p class="text-sm text-gray-500">{{ review.company }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";

const reviews = ref([]);

onMounted(async () => {
  try {
    const response = await axios.get(
      "https://665dba59e88051d6040803cc.mockapi.io/addquestions"
    ); // Replace with your actual API endpoint
    reviews.value = response.data;
  } catch (error) {
    console.error("Error fetching reviews:", error);
  }
});
</script>
