<template>
  <div
    class="h-screen mt-32 bg-cover bg-center flex items-center justify-center relative"
  >
    <!-- Background Blur -->
    <div
      class="absolute inset-0 bg-gray-1 bg-opacity-50 backdrop-blur-md"
    ></div>

    <!-- Card Container -->
    <div
      data-aos="fade-up"
      data-aos-duration="1500"
      class="relative flex flex-col border-2 lg:flex-row w-full lg:w-3/4 h-auto lg:h-[90vh] bg- shadow-lg rounded-[50px] overflow-hidden"
    >
      <!-- Left Section: Image -->
      <div
        class="hidden lg:flex w-full lg:w-2/3 items-center bg-login-blue"
        data-aos="fade-right"
        data-aos-duration="1500"
      >
        <img
          :src="require('@/assets/aboutus.jpeg')"
          alt="Feedback"
          class="w-full h-full object-cover"
        />
      </div>

      <!-- Right Section: Feedback Form -->
      <div
        class="w-full lg:w-1/3 flex flex-col justify-center p-8 lg:p-16 bg-gray-50 border-r-4 border-blue-500"
        data-aos="fade-left"
        data-aos-duration="1500"
      >
        <h2
          class="text-gray-800 text-xl lg:text-2xl font-semibold mb-6 text-center"
        >
          Submit Feedback
        </h2>
        <form @submit.prevent="submitForm" class="space-y-4">
          <!-- Name Input -->
          <div>
            <label
              for="name"
              class="block text-sm font-medium text-gray-700 text-left"
              >Name</label
            >
            <input
              v-model="formData.name"
              type="text"
              id="name"
              placeholder="Enter your name"
              class="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-orange-500"
              required
            />
          </div>

          <!-- Company Input (Now Optional) -->
          <div>
            <label
              for="company"
              class="block text-sm font-medium text-gray-700 text-left"
              >Company</label
            >
            <input
              v-model="formData.company"
              type="text"
              id="company"
              placeholder="Enter company name (optional)"
              class="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>

          <!-- Rating Input -->
          <div>
            <label
              for="rating"
              class="block text-sm font-medium text-gray-700 text-left"
              >Rating</label
            >
            <input
              v-model="formData.rating"
              type="number"
              id="rating"
              placeholder="Enter rating (1-5)"
              class="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-orange-500"
              min="1"
              max="5"
              required
            />
          </div>

          <!-- Feedback Textarea -->
          <div>
            <label
              for="text"
              class="block text-sm font-medium text-gray-700 text-left"
              >Feedback</label
            >
            <textarea
              v-model="formData.text"
              id="text"
              placeholder="Enter your feedback"
              class="w-full border border-gray-300 rounded-2xl py-2 px-4 focus:outline-none focus:ring-2 focus:ring-orange-500"
              rows="4"
              required
            ></textarea>
          </div>

          <!-- Date Input -->
          <div>
            <label
              for="date"
              class="block text-sm font-medium text-gray-700 text-left"
              >Date</label
            >
            <input
              v-model="formData.date"
              type="date"
              id="date"
              class="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-orange-500"
              required
            />
          </div>

          <!-- Submit Button -->
          <button
            type="submit"
            class="bg-my-dark text-white py-2 px-6 rounded-full hover:bg-primary transition w-full"
          >
            Submit
          </button>
        </form>

        <!-- Success/Error Messages -->
        <div v-if="successMessage" class="mt-4 text-logo-color">
          {{ successMessage }}
        </div>
        <div v-if="errorMessage" class="mt-4 text-logo-color">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router"; // Import Vue Router

export default {
  setup() {
    const formData = reactive({
      name: "",
      company: "", // Optional field
      rating: "",
      text: "",
      date: "",
    });

    const successMessage = ref("");
    const errorMessage = ref("");
    const router = useRouter(); // Create router instance

    const submitForm = async () => {
      try {
        const response = await fetch(
          "https://665dba59e88051d6040803cc.mockapi.io/addquestions",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to submit the form");
        }

        successMessage.value = "Feedback submitted successfully!";
        errorMessage.value = "";

        // Reset the form data after submission
        formData.name = "";
        formData.company = ""; // This remains empty if not filled out
        formData.rating = "";
        formData.text = "";
        formData.date = "";

        // Add a 2-second delay before routing
        setTimeout(() => {
          router.push({ name: "home" }); // Replace 'home' with your actual home route name
        }, 2000);
      } catch (error) {
        errorMessage.value = error.message;
        successMessage.value = "";
      }
    };

    return {
      formData,
      successMessage,
      errorMessage,
      submitForm,
    };
  },
};
</script>

<style scoped>
.backdrop-blur-md {
  backdrop-filter: blur(1px);
}
</style>
